import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import packageJSON from "../../package.json";
import { getStorageItem, removeStorageItem } from "./localStorage";

const NEST_URL = process.env.REACT_APP_NEST_CUSTOMERS;
const NODE_URL = process.env.REACT_APP_NODE_API;
const LICENCIAMENTO_URL = process.env.REACT_APP_LICENSIAMENTO;

export const apiV1 = axios.create({
  baseURL: `${NEST_URL}v1/api/yooga/`,
});

export const apiV2 = axios.create({
  baseURL: `${NEST_URL}v2/api/yooga/`,
});

export const apiNestNode = axios.create({
  baseURL: `${NEST_URL}node-api/`,
});

export const apiDefault = axios.create({
  baseURL: `${NEST_URL}v1/api/`,
});

export const licenciamento = axios.create({
  baseURL: LICENCIAMENTO_URL,
  headers: {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mzc5ODMxMzEsImlkIjoyMSwibG9naW4iOiJtYXJjdXMucmFtcGluZWxsaUB5b29nYS5jb20uYnIiLCJvcmlnX2lhdCI6MTczNTM5MTEzMX0.H1bkQZKmsrD94syiYBU_N6SDg9dQET0X3c6uVlhXp7s",
  },
});

export const nodeApi = axios.create({
  baseURL: NODE_URL,
});

// ------------------------------------------------------------
// MIDDLEWARES
// ------------------------------------------------------------

function handleHeaders(config: InternalAxiosRequestConfig) {
  const token = getStorageItem("token");

  // Append default headers
  config.headers!["Accept"] = "application/json";
  config.headers!["Content-Type"] = "application/json";
  config.headers!["version"] = packageJSON.version;
  config.headers!["timezone-version"] = "true";
  config.headers!["auto-increase"] = "true";

  // Append JWT Token if exists
  if (token) {
    config.headers!["Authorization"] = `Bearer ${token}`;
  }

  return config;
}

function handleRequestError(error: any) {
  return Promise.reject(error);
}

function handleUnauthorized(error: AxiosError) {
  if (error.response && error.response?.status === 401) {
    removeStorageItem("token");
    window.location.href = "/login";
  }
  return Promise.reject(error);
}

apiV1.interceptors.request.use(handleHeaders, handleRequestError);
apiV1.interceptors.response.use(null, handleUnauthorized);

apiV2.interceptors.request.use(handleHeaders, handleRequestError);
apiV2.interceptors.response.use(null, handleUnauthorized);

apiDefault.interceptors.request.use(handleHeaders, handleRequestError);
apiDefault.interceptors.response.use(null, handleUnauthorized);

apiNestNode.interceptors.request.use(handleHeaders, handleRequestError);
apiNestNode.interceptors.response.use(null, handleUnauthorized);
